<template>
    <div class="images" v-viewer.static="{inline: true,toolbar: false,navbar: false,transition: false,}">
        <img :src="image" @error="onErrorImage($event)">
    </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'

export default {
    components: {
    },
    props: {
    },
    data() {
        return {
          image: '',
        };
    },
    computed: {},
    watch: {
    },
    created() {},
    mounted() {
      this.image = this.b64_to_utf8(this.$route.params.src);
    },
    destroyed() {},
    methods: {
    },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";

.images img {
  display: none;
}

</style>